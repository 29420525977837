<template>
        <ValidationObserver ref="payobserver" tag="form" v-slot="{ handleSubmit }" @submit.prevent>
            <b-row>
                <b-col cols="12">
                    <div>
                        <b-row no-gutters align-v="center">
                            <b-col sm="12">
                                <label class="mb-1">Cardnumber</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Cardnumber" rules="required" v-slot="v">
                                    <b-form-input v-model="paymentCard.cardnumber" type="text"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="6">
                                <label class="mb-1">Expires</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Expires" rules="required" v-slot="v">
                                    <b-form-input spellcheck="false" type="text"
                                        v-model="paymentCard.expdate"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                                <label class="mb-1">CSV</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="CSV" rules="required" v-slot="v">
                                    <b-form-input spellcheck="false" type="text" v-model="paymentCard.csv"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">Cardholder</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Cardholder" rules="required" v-slot="v">
                                    <b-form-input spellcheck="false" type="text"
                                        v-model="paymentCard.cardholder"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mt-2 mb-0">
                        <b-col cols="12">
                            <div class="float-left">
                                <b-button variant="warning" @click="handleSubmit(closeModal())">Cancel
                                </b-button>
                            </div>
                            <div class="float-right">
                                <b-button variant="success" @click="handleSubmit(HandlePayment())">Pay
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </ValidationObserver>
</template>

<style scoped></style>

<script>
export default {
    data() {
        return {
            paymentCard: {
                cardnumber: '',
                expdate: '',
                cardholder: '',
                csv: ''
            }
        }
    },
    methods: {
        HandlePayment() {
            this.$refs.payobserver.validate().then(success => {
                console.log("PAYMENT");
            });

        },
        closeModal() {
            this.$emit('closepayment');
        },
    },
    mounted() {

    }
}
</script>