<template>
    <div>
        <gate-subscriptions v-if="gate.id > 0" :gate="gate" :subscription-list="subcriptionList" />

    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import gateService from '@/services/gateService';
    import router from '@/router';
    import subscriptionService from '@/services/subscriptionService';
    import gateSubscriptionsComponent from '@/components/gates/gate-subscriptions.vue';
    import { UserRoles } from '@/variables/variables';
    export default {
        components: {
            'gate-subscriptions': gateSubscriptionsComponent,
        },
        props: ["gateId"],
        data() {
            return {
                gate: {
                    id: 0,
                    systemName: '',
                    name: '',
                    orderNumber: '',
                    gateDescription: '',
                    roleId: 0,
                    deviceId: 0,
                    ownerCustomerId: 0,
                    gateInstallerCustomerId: 0,
                    countryId: 0,
                    timeZoneId: 0,
                    createdOnUtc: null,
                    deletedOnUtc: null,
                    deleted: false,
                    status: 0,
                    gateSchedule: { enabled: false },
                    device: '',
                    demoMode: false,
                    discount: 0
                },
                //subscriptionTemplateList: [],
                subcriptionList: [],
                userRoles: UserRoles,
                subscriptionGateModel: {
                    subscriptionId: 0,
                    gateId: 0,
                    duration: 1,
                },
                subOptions: [

                    { text: 'One month', value: 1 },
                    { text: 'Six months', value: 6 },
                    { text: 'Twelve months', value: 12 },
                ],
            }
        },
        methods: {
            addSubscriptionToGate(subId) {
                //if (str === "init") {
                //    this.subscriptionGateModel.duration = 0;
                //    this.subscriptionGateModel.isInitial = true;
                //}
                this.subscriptionGateModel.subscriptionId = subId;
                this.subscriptionGateModel.gateId = this.gateId;
                console.log("subscriptionGateModel: ", this.subscriptionGateModel)
                subscriptionService.addSubscriptionToGate(this.subscriptionGateModel)
                    .then((data) => {
                        router.push({ name: 'Nets', params: { gateId: this.gateId} })
                        console.log(data);
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            //getSubscriptionTemplateList() {
            //    subscriptionService.getSubscriptionTemplateList()
            //        .then((data) => {
            //            this.subscriptionTemplateList = data;
            //        })
            //        .catch(function (error) {
            //            alert(error);
            //        });
            //},
            fillView(data) {
                if (data.gate) {
                    this.gate = data.gate;
                }
                this.deviceList = data.devices;
                this.gateIOList = data.gateIOs;
                this.gateTypeList = data.gateTypeList;
                this.icons = data.icons;
                this.subcriptionList = data.subscriptions;
                this.countryList = data.countries;
                this.timeZoneList = data.timeZones;

                this.customerOwnerList = data.customerOwners;
                this.gateInstallerList = data.customerGateInstallers;

                if (this.gate.id) {
                    this.outputSignalTypes = data.outputSignalTypes;
                    this.inputSignalTypes = data.inputSignalTypes;
                }
            },
        },
        computed: {
            ...mapState('authentication', {
                stateUser: 'user',
            }),
        },
        mounted() {

            if (this.gateId > 0) {
                gateService.getGate(this.gateId).then(data => {
                    this.gateData = data;
                    console.log("GATEDATA", data)
                    this.fillView(data);
                    this.boolArray = data.boolArray;
                    if (this.boolArray) {
                        this.gateOpened = this.boolArray[10].status;
                    }
                });
            } else {
                gateService.newGate().then(data => {
                    this.fillView(data);
                });
            }
        }
    }


</script>