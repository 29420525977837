"use strict";

import axios from "axios";
const baseControllerAddress = 'subscription/';

export default {
    getLocale: (id) => {
        return axios.post('/api/' + 'locale/GetById/', id)
            .then(result => {
                return result;
            }).catch(function (error) {
                alert(error);
            });
    },
    getSubscriptionTemplateList: (gateId) => {
        return axios.get('/api/' + baseControllerAddress + "GetAllSubscriptionTemplates/" + gateId)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    createSubTemplate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "CreateSubTemplate", vm)
        .then(result => {
            return result.data;
        }).catch(function (error) {
                alert(error);
            });
    },
    updateSubTemplate: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "UpdateSubTemplate", vm)
            .catch(function (error) {
                alert(error);
            });
    },
    getSubTemplate: (id) => {
        return axios.get('/api/' + baseControllerAddress + "GetSubTemplate/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    fetchSubTemplateValues: (action) => {
        return axios.get('/api/' + baseControllerAddress + action)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    addSubscriptionToGate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "AddSubscriptionToGate", vm)
        .then(result => {
            return result.data;
        }).catch(function (error) {
                alert(error);
            });
    },
    getGateSubscriptionsById: (id) => {
        return axios.get('/api/' + baseControllerAddress + "GetAllGateSubscriptionsById/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getGateSubscriptions: (vm) => {
        return axios.get('/api/' + baseControllerAddress + "GetAllGateSubscriptions")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
}